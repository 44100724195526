<template>
	<div class="tw-pb-6">
		<div class="tw-flex tw-items-center tw-justify-between">
			<h2 class="tw-text-xl tw-font-semibold tw-mb-7">{{ $t('offers') }}</h2>
		</div>
		<v-row class="tw-mt-9">
			<v-col
				v-for="(item, i) in $offers.data"
				:key="i"
				cols="12"
				sm="6"
				md="4"
				lg="3"
				xl="2"
			>
				<OfferCard :offer="item" class="tw-flex-none" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { numberWithCommas } from '../helpers'

import OfferCard from '../components/customs/OfferCard.vue'
export default {
	name: 'AllOffers',
	components: {
		OfferCard,
	},
	computed: {
		...mapGetters('offers', ['$offers']),
	},
	methods: {
		...mapActions('offers', ['getRunningOffers']),
		formatPrice(price) {
			console.log(price)
			return numberWithCommas(price)
		},
		goToSingleProduct(id) {
			console.log('goToSingleProduct')
			this.$router.push(`product/${id}`)
		},
	},
	created() {
		this.getRunningOffers({ per_page: 1000 })
	},
}
</script>

<style lang="scss">
// .custom-outline-text-field .v-text-field--outlined >>> fieldset {
// 	border-color: rgba(192, 0, 250, 0.986);
// }
.custom-outline-text-field.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.price-range .v-slider__thumb {
	background-color: rgb(255, 255, 255);
	border-color: #d9d9d9 !important;
	border: 1px solid;
	width: 32px !important;
	height: 32px !important;
}
.price-range .v-slider__track-container {
	height: 8px !important;
}
.price-range .v-slider__track-fill,
.v-slider__track-background {
	border-radius: 6px !important;
}
.price-range .v-slider__thumb:after {
	display: block;
	width: 18px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	margin-top: 1px;
}
</style>
